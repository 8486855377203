//ARTICLE SHOW

.articleShow {
  &__mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    background-color: #f9f9f9;
  }

  &__componentContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 2rem 0;
  }
}

.newspaper {
  max-width: 1350px;
  margin: 80px auto 0;
  padding: 0;
  font-family: "Libre Baskerville", Georgia, serif;
  color: #333;
  background-color: #fff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  &__subscribe-container {
    padding: 10px;

    &.sticky {
      position: fixed;
      top: 117px; // Navbar height
      width: 300px; // Set appropriate width

      @include query(max-w-775) {
        position: relative;
        top: 0;
        width: 100%;
      }
    }

    &.bottom-stuck {
      position: absolute;
      bottom: 0;
      width: 300px; // Same as sticky width

      @include query(max-w-775) {
        position: relative;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &__date {
    font-size: clamp(12px, 1vw, 14px);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: #666;

    @include query(max-w-775) {
      order: 2;
    }
  }

  &__divider {
    height: 3px;
    background-color: #000;
    margin: 15px 0 5px;

    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #000;
      margin-top: 2px;
    }
  }

  // Content
  &__content {
    max-width: 1200px;
    margin: 30px auto 0;
    padding: 40px 40px 60px;

    @include query(max-w-775) {
      padding: 30px 20px 40px;
    }
  }

  &__title {
    font-size: clamp(24px, 4vw, 40px);
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: "Playfair Display", Georgia, serif;
    text-align: center;
    color: #000;
  }

  &__byline {
    font-size: clamp(14px, 1.2vw, 16px);
    font-style: italic;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    color: #555;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 0 0 30px;

    @include query(max-w-480) {
      margin-bottom: 20px;
      padding-bottom: 15px;
      justify-content: center;
    }
  }

  &__byline-info {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__byline-share {
    // Styles specific to the share button container if needed
  }

  &__author {
    font-weight: bold;
  }

  &__category {
    text-transform: uppercase;
    font-size: clamp(10px, 0.9vw, 12px);
    background-color: $color-primary-blue;
    padding: 3px 8px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    color: $color-primary-yellow;
  }

  // Main Banner Image
  &__main-image {
    margin: 0 -40px 30px;
    overflow: hidden;
    max-height: 500px;

    @include query(max-w-775) {
      margin: 0 -20px 25px;
      max-height: 400px;
    }

    @include query(max-w-480) {
      max-height: 300px;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }

  &__image-caption {
    font-size: clamp(10px, 1vw, 12px);
    font-style: italic;
    color: #666;
    text-align: center;
    padding: 5px 40px;

    @include query(max-w-775) {
      padding: 0 20px;
    }
  }

  // Lead Paragraph
  &__lead-paragraph {
    font-size: clamp(16px, 1.8vw, 20px);
    line-height: 1.7;
    margin-bottom: 35px;
    color: #333;
    font-family: "Libre Baskerville", Georgia, serif;

    @include query(max-w-775) {
      margin-bottom: 30px;
    }

    &:first-letter {
      font-size: clamp(40px, 5vw, 60px);
      font-weight: bold;
      float: left;
      padding: 4px 12px 0 0;
      line-height: 0.8;
      color: #000;
    }
  }

  // Article Layout
  &__article-layout {
    display: grid;
    grid-template-columns: 2.25fr 0.75fr;
    gap: 20px;
    margin-top: 30px;

    // @include query(max-w-900) {
    //   grid-template-columns: 1.5fr 1fr;
    //   gap: 30px;
    // }

    @include query(max-w-775) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }

  // Main Column
  &__main-column {
    border-right: 1px solid #eee;
    padding-right: 40px;

    @include query(max-w-900) {
      padding-right: 30px;
    }

    @include query(max-w-775) {
      border-right: none;
      padding-right: 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 40px;
    }
  }

  // Text Content
  &__text-content {
    font-size: clamp(15px, 1.5vw, 17px);
    line-height: 1.8;
    color: #333;
  }

  &__section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    font-size: clamp(22px, 2.4vw, 26px);
    font-family: "Playfair Display", Georgia, serif;
    font-weight: 700;
    margin: 40px 0 20px;
    color: #000;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    @include query(max-w-775) {
      margin: 30px 0 15px;
    }
  }

  &__paragraph {
    margin-bottom: 30px;

    p {
      margin-bottom: 20px;
      text-align: justify;
      hyphens: auto;
    }
  }

  &__rich-text {
    margin-bottom: 20px;
    font-size: clamp(12px, 1.5vw, 15px);

    p {
      margin-bottom: 20px;
      text-align: justify;
      line-height: 1.8;
    }

    h4 {
      font-weight: 700;
      margin: 25px 0 15px;
      font-size: clamp(16px, 1.7vw, 19px);
    }

    ul,
    ol {
      margin: 20px 0 20px 10px;

      li {
        margin-bottom: 10px;
      }
    }

    blockquote {
      margin: 30px 40px;
      padding-left: 20px;
      border-left: 3px solid #999;
      font-style: italic;
      color: #555;

      @include query(max-w-775) {
        margin: 20px;
      }
    }

    // Add clearfix to ensure proper text flow around floated images
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &__subheading {
    font-size: clamp(18px, 2vw, 22px);
    font-weight: 700;
    margin: 35px 0 20px;
    font-family: "Playfair Display", Georgia, serif;
    color: #000;

    @include query(max-w-775) {
      margin: 30px 0 15px;
    }
  }

  &__inline-image {
    margin: 30px 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: ghostwhite;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    }

    img {
      display: block;
      width: 100%;
    }

    @include query(max-w-775) {
      margin: 25px 0;
    }

    // Image placement variations
    &--right {
      float: right;
      width: 40%;
      margin: 5px 0 20px 30px;

      @include query(max-w-900) {
        max-width: 45%;
        margin: 5px 0 15px 20px;
      }

      @include query(max-w-775) {
        float: none;
        max-width: 100%;
        margin: 25px 0;
      }
    }

    &--left {
      float: left;
      width: 40%;
      margin: 5px 30px 20px 0;

      @include query(max-w-900) {
        max-width: 45%;
        margin: 5px 20px 15px 0;
      }

      @include query(max-w-775) {
        float: none;
        max-width: 100%;
        margin: 25px 0;
      }
    }

    &--above {
      margin-bottom: 30px;
      margin-top: 0;
      clear: both; // Ensure it clears any previous floats
    }

    &--below {
      margin-top: 30px;
      margin-bottom: 0;
      clear: both; // Ensure it clears any previous floats
    }

    &--center {
      margin: 30px auto;
      max-width: 80%;
      clear: both; // Ensure it clears any previous floats

      @include query(max-w-775) {
        max-width: 100%;
      }
    }
  }

  // Utility for clearing floats
  &__clearfix {
    clear: both;
    width: 100%;
    height: 1px;
    content: "";
    display: block;
    visibility: hidden;
  }

  // Secondary Column
  &__secondary-column {
    padding-top: 10px;
    min-width: 300px;
    position: relative;

    @include query(max-w-775) {
      padding-top: 0;
    }
  }

  // Related Content
  &__related-content {
    background-color: #f9f9f9;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
  }

  &__related-heading,
  &__tags-heading,
  &__gallery-heading {
    font-size: clamp(16px, 1.6vw, 18px);
    font-weight: 700;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    font-family: "Playfair Display", Georgia, serif;
    color: #000;
  }

  &__related-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 12px;
      padding-left: 18px;
      position: relative;

      &:before {
        content: "•";
        position: absolute;
        left: 0;
        color: #999;
        font-size: clamp(16px, 1.6vw, 18px);
        line-height: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__related-link {
    color: #333;
    text-decoration: none;
    font-size: clamp(14px, 1.4vw, 16px);
    line-height: 1.5;
    transition: color 0.2s ease;

    &:hover {
      color: #8b0000;
      text-decoration: underline;
    }
  }

  // Tags
  &__tags-container {
    background-color: #f9f9f9;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__tag {
    background-color: #fff;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: clamp(11px, 1.1vw, 13px);
    border: 1px solid #ddd;
    color: #555;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
      border-color: #ccc;
    }
  }

  // Gallery
  &__gallery {
    background-color: #f9f9f9;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
  }

  &__gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 15px;
  }

  &__gallery-item {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

    img {
      transition: transform 0.3s ease;
      display: block;
      width: 100%;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  // Fallback content
  &__fallback-content {
    font-size: clamp(15px, 1.5vw, 17px);
    line-height: 1.8;
    color: #555;

    p {
      margin-bottom: 20px;
    }
  }

  &__error-message {
    background-color: #fff8f8;
    padding: 15px;
    border-left: 3px solid #ffb6b6;
    font-size: clamp(12px, 1.2vw, 14px);
    color: #c00;
    margin-top: 20px;
  }

  &__notice-message {
    background-color: #f0f8ff;
    padding: 15px;
    border-left: 3px solid #b6d0ff;
    font-size: clamp(12px, 1.2vw, 14px);
    color: #0066cc;
    margin-top: 20px;
  }

  // Loading state
  &__loading {
    padding: 30px 0;
    text-align: center;
    color: #666;

    p {
      margin-bottom: 15px;
      font-style: italic;
    }

    &-spinner {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 3px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top-color: #8b0000;
      animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

  // Article Footer
  &__article-footer {
    margin-top: 60px;
    padding-top: 20px;
    border-top: 1px solid #ddd;

    @include query(max-w-775) {
      margin-top: 40px;
    }
  }

  &__metadata {
    display: flex;
    justify-content: space-between;
    font-size: clamp(12px, 1.2vw, 14px);
    color: #666;

    @include query(max-w-775) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__published-date {
    font-style: italic;
  }

  &__author-info {
    max-width: 60%;

    @include query(max-w-775) {
      max-width: 100%;
    }
  }

  &__author-title {
    font-weight: 700;
    margin-bottom: 5px;
  }

  &__author-bio {
    font-size: clamp(12px, 1.2vw, 14px);
    line-height: 1.6;
    color: #555;
  }

  // Error state
  &__error {
    max-width: 600px;
    margin: 100px auto;
    text-align: center;
    padding: 40px 20px;

    h2 {
      font-size: clamp(20px, 2.2vw, 24px);
      margin-bottom: 15px;
      color: #333;
    }

    p {
      font-size: clamp(14px, 1.4vw, 16px);
      color: #666;
    }
  }

  // Comments section
  &__comments-section {
    margin-top: 60px;
    padding-top: 40px;
    border-top: 2px solid #eee;

    @include query(max-w-775) {
      margin-top: 40px;
      padding-top: 30px;
    }
  }

  &__comments-header {
    margin-bottom: 30px;
  }

  &__comments-title {
    font-family: "Playfair Display", Georgia, serif;
    font-size: clamp(20px, 2.2vw, 24px);
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__comments-icon {
    color: #8b0000;
  }

  &__comments-count {
    font-size: clamp(16px, 1.8vw, 18px);
    color: #777;
    font-weight: normal;
    margin-left: 5px;
  }

  &__comment-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eee;
  }

  &__comment-author-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: clamp(14px, 1.4vw, 16px);
    background-color: #fff;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #8b0000;
      box-shadow: 0 0 0 2px rgba(139, 0, 0, 0.1);
      transform: translateY(-2px);
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  &__comment-input {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: clamp(14px, 1.4vw, 16px);
    resize: vertical;
    background-color: #fff;
    margin-bottom: 15px;

    &:focus {
      outline: none;
      border-color: #8b0000;
      box-shadow: 0 0 0 2px rgba(139, 0, 0, 0.1);
    }
  }

  &__comment-submit {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #8b0000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: clamp(14px, 1.4vw, 16px);
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken(#8b0000, 10%);
    }

    &:disabled {
      background-color: #999;
      cursor: not-allowed;
    }
  }

  &__submit-text {
    @include query(max-w-480) {
      display: none;
    }
  }

  &__comments-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__comment {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    @include query(max-w-480) {
      gap: 15px;
    }
  }

  &__comment-avatar {
    flex-shrink: 0;
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    @include query(max-w-480) {
      width: 45px;
      height: 45px;
    }
  }

  &__comment-content {
    flex: 1;
  }

  &__comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @include query(max-w-480) {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  &__comment-author {
    font-family: "Playfair Display", Georgia, serif;
    font-size: clamp(16px, 1.6vw, 18px);
    color: #000;
    margin: 0;
  }

  &__comment-date {
    font-size: clamp(12px, 1.2vw, 14px);
    color: #777;
    font-style: italic;
  }

  &__comment-text {
    font-size: clamp(14px, 1.4vw, 16px);
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
  }

  &__comment-actions {
    display: flex;
    gap: 15px;
  }

  &__like-button,
  &__more-button {
    background: none;
    border: none;
    color: #777;
    font-size: clamp(14px, 1.4vw, 16px);
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 5px;
    transition: color 0.2s ease;

    &:hover {
      color: #333;
    }

    &--active {
      color: #8b0000;

      &:hover {
        color: darken(#8b0000, 10%);
      }
    }
  }

  &__like-count {
    font-size: clamp(12px, 1.2vw, 14px);
  }

  &__no-comments {
    text-align: center;
    padding: 40px 0;
    color: #777;
    font-style: italic;
    border: 1px dashed #ddd;
    border-radius: 8px;
    background-color: #fafafa;

    p {
      font-size: clamp(14px, 1.4vw, 16px);
    }
  }

  &__comments-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

    p {
      margin-top: 20px;
      font-size: clamp(14px, 1.4vw, 16px);
      color: #777;
      font-style: italic;
    }

    .newspaper__loading-spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgba(139, 0, 0, 0.1);
      border-radius: 50%;
      border-top-color: #8b0000;
      animation: spin 1s ease-in-out infinite;
    }
  }

  // Newsletter Subscription Styles
  &__newsletter {
    padding: 10px;
    margin-bottom: 30px;
    top: 20px;
    transition: all 0.3s ease;
    max-height: calc(100vh - 40px);
    overflow-y: auto;

    @include query(max-w-775) {
      position: relative;
      top: 0;
      margin-top: 30px;
    }
  }

  &__newsletter-title {
    font-size: clamp(16px, 1.6vw, 18px);
    font-weight: 700;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    font-family: "Playfair Display", Georgia, serif;
    color: #000;
  }

  &__newsletter-text {
    font-size: clamp(12px, 1.2vw, 14px);
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  &__newsletter-form {
    margin-top: 15px;
  }

  &__newsletter-form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;

    @include query(max-w-480) {
      flex-direction: column;
    }
  }

  &__newsletter-input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: clamp(12px, 1.2vw, 14px);
    transition: border-color 0.2s ease;

    &:focus {
      outline: none;
      border-color: #8b0000;
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  &__newsletter-submit {
    background-color: #8b0000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-family: "Libre Baskerville", Georgia, serif;
    font-size: clamp(12px, 1.2vw, 14px);
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;

    &:hover {
      background-color: darken(#8b0000, 10%);
    }

    &:disabled {
      background-color: #999;
      cursor: not-allowed;
    }
  }

  &__newsletter-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }

  &__newsletter-success {
    background-color: #f0fff4;
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    color: #2f855a;

    h5 {
      font-size: clamp(14px, 1.4vw, 16px);
      margin: 0;
    }
  }

  &__newsletter-error {
    background-color: #fff5f5;
    color: #c53030;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: clamp(12px, 1.2vw, 14px);
  }

  &__newsletter-feedback {
    color: #c53030;
    font-size: clamp(12px, 1.2vw, 14px);
    margin-top: 5px;
  }

  &__comment-error {
    background-color: #fff8f8;
    padding: 15px;
    border-left: 3px solid #ffb6b6;
    font-size: clamp(12px, 1.2vw, 14px);
    color: #c00;
    margin-top: 20px;
  }

  &__comment-error-alert {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff8f8;
    padding: 15px;
    border-radius: 4px;
    margin: 15px 0;
    border: 1px solid #ffb6b6;

    p {
      margin: 0;
      font-size: clamp(14px, 1.2vw, 16px);
      color: #c00;
    }

    &-icon {
      color: #c00;
      font-size: 16px;
    }
  }
}

// Share Component Styles
.share-component {
  position: relative; // Needed for fallback absolute positioning
}

.share-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: 1px solid #ccc;
  color: #555;
  padding: 6px 12px;
  border-radius: 20px;
  font-family: inherit;
  font-size: clamp(12px, 1vw, 14px);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
    border-color: #bbb;
    color: #333;
  }

  &__text {
    @include query(max-w-480) {
      // Hide text on small screens if needed
      // display: none;
    }
  }
}

.share-fallback {
  position: absolute;
  top: 100%; // Position below the button
  right: 0; // Align left edge with button's left edge
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.share-fallback__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(13px, 1.1vw, 15px);
  font-weight: 600;
  color: #444;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.share-fallback__close {
  background: none;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
  padding: 2px;

  &:hover {
    color: #333;
  }
}

.share-fallback__links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.share-fallback__link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  font-size: clamp(13px, 1.1vw, 15px);
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  svg {
    font-size: 18px;
    width: 20px; // Ensure consistent icon width
    text-align: center;
    color: #666;
  }

  // Specific brand colors on hover (optional)
  &:hover[title*="Twitter"] svg {
    color: #1da1f2;
  }
  &:hover[title*="Facebook"] svg {
    color: #1877f2;
  }
  &:hover[title*="LinkedIn"] svg {
    color: #0a66c2;
  }
  &:hover[title*="WhatsApp"] svg {
    color: #25d366;
  }
  &:hover[title*="Email"] svg {
    color: #dd4b39;
  }
}

.share-fallback__link-text {
  // Optional: Hide text if you only want icons
}
