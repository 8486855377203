//=============================
//HOME ARTICLE SECTION
//=============================

.homeArticleSection {
  background-color: $background-color;
  padding: 9rem 0;
  position: relative;
  overflow: hidden;

  // Subtle background pattern
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
      circle at 75% 25%,
      rgba($color-primary-blue, 0.03) 1%,
      transparent 3%
    );
    background-size: 40px 40px;
    opacity: 0.6;
    z-index: 0;
  }

  &__container {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  &__titleContainer {
    text-align: center;
    margin-bottom: 4rem;

    @include query(max-w-768) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    font-size: clamp(2.8rem, 5vw, 3.8rem);
    color: $color-primary-blue;
    font-weight: 700;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background-color: $color-primary-yellow;
      border-radius: 2px;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    color: rgba($color-primary-blue, 0.8);
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 1.6;
  }

  &__carouselContainer {
    margin-bottom: 5rem;
    border-radius: 1.2rem;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    position: relative;

    // Customize the arrow icons
    .image-gallery-left-nav,
    .image-gallery-right-nav {
      background-color: rgba(0, 0, 0, 0.3);
      padding: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.3s ease;
      margin: 0 1.5rem;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }

      .image-gallery-svg {
        width: 30px !important;
        height: 30px !important;

        @include query(max-w-768) {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    // Fix image height
    .image-gallery-slide {
      height: auto;

      .image-gallery-image {
        object-fit: cover;
        height: 550px;

        @include query(max-w-1024) {
          height: 500px;
        }

        @include query(max-w-768) {
          height: 450px;
        }

        @include query(max-w-480) {
          height: 400px;
        }
      }
    }

    // Customize bullet indicators
    .image-gallery-bullets {
      bottom: 20px;
      z-index: 10;

      .image-gallery-bullet {
        border: 2px solid white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        opacity: 0.8;

        &.active {
          transform: scale(1.2);
          opacity: 1;
        }

        // Bullet sizes for different placements
        &.bullet-primary {
          padding: 7px;
        }

        &.bullet-secondary {
          padding: 6px;
        }

        &.bullet-tertiary,
        &.bullet-regular {
          padding: 5px;
        }
      }
    }

    // Custom styling for the slide description
    .image-gallery-description {
      background: none;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: auto;
    }
  }

  // Slide content styles
  &__slideContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__slideTextContainer {
    width: 90%;
    max-width: 800px;
    padding: 4rem;
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);

    @include query(max-w-768) {
      padding: 3rem;
    }

    @include query(max-w-480) {
      padding: 2rem;
      width: 100%;
    }
  }

  &__slideCategory {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $color-primary-blue;
    color: white;
    padding: 0.8rem 1.6rem 0.6rem 1.6rem;
    border-radius: 2rem;
    margin-bottom: 1.6rem;
    letter-spacing: 0.1em;
  }

  &__slideTitle {
    font-size: 3.6rem;
    font-weight: 700;
    color: white;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

    @include query(max-w-1024) {
      font-size: 3rem;
    }

    @include query(max-w-768) {
      font-size: 2.6rem;
    }

    @include query(max-w-480) {
      font-size: 2.2rem;
    }
  }

  &__slideSummary {
    font-size: 1.7rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2.5rem;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    text-align: justify;

    @include query(max-w-768) {
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: 2rem;
    }

    @include query(max-w-480) {
      display: none;
    }
  }

  &__slideButton {
    display: inline-block;
    background-color: $color-primary-yellow;
    color: $color-primary-blue;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 2.5rem;
    border-radius: 3rem;
    transition: all 0.3s ease;
    text-decoration: none;

    &:hover {
      background-color: $color-primary-yellow;
      transform: translateY(-3px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      color: $color-primary-blue;
    }

    @include query(max-w-768) {
      font-size: 1.4rem;
      padding: 0.8rem 2rem;
    }
  }

  &__emptyState {
    text-align: center;
    padding: 4rem 2rem;
    background-color: rgba(white, 0.7);
    border-radius: 1.2rem;
    margin-bottom: 4rem;

    p {
      font-size: 1.8rem;
      color: $color-primary-blue;
      opacity: 0.7;
    }
  }

  &__viewAll {
    text-align: center;
    margin-top: 2rem;
  }

  &__viewAllLink {
    display: inline-flex;
    align-items: center;
    color: $color-primary-yellow;
    background-color: $color-primary-blue;
    font-size: 1.8rem;
    font-weight: 600;
    transition: all 0.3s ease;
    padding: 1rem 2rem;
    border: 2px solid $color-primary-blue;
    border-radius: 1rem;
    text-decoration: none;
    &:hover {
      background-color: $color-primary-yellow;
      color: $color-primary-blue;
      border: 2px solid $color-primary-yellow;
      transform: translateY(-2px);

      .homeArticleSection__arrow {
        transform: translateX(5px);
      }
    }
  }

  &__arrow {
    margin-left: 1rem;
    transition: transform 0.3s ease;
  }
}

.badge {
  background-color: $color-primary-blue;
  color: $color-primary-yellow;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  margin: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

//=============================
//HOME ARTICLE SECTION CARD
//=============================

.homeArticleSectionCard {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;

  &__image {
    object-fit: cover;
    height: 450px;

    @include query(max-w-768) {
      height: 300px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    flex: 1;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.3;
    color: $color-primary-blue;
    margin-bottom: 1.5rem;
    transition: color 0.2s ease;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover {
      color: darken($color-primary-blue, 15%);
    }

    @include query(max-w-768) {
      font-size: 2rem;
    }
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    color: rgba($color-primary-blue, 0.7);
    align-items: center;
  }

  &__author {
    font-weight: 500;
  }

  &__date {
    position: relative;
    padding-left: 1.2rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba($color-primary-blue, 0.5);
    }
  }

  &__readTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    i {
      color: $color-primary-yellow;
    }
  }

  &__summary {
    font-size: 1.6rem;
    line-height: 1.6;
    color: rgba($color-primary-blue, 0.8);
    margin-bottom: 2rem;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include query(max-w-768) {
      font-size: 1.5rem;
      -webkit-line-clamp: 2;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  &__tags {
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
  }

  &__tag {
    background-color: rgba($color-primary-blue, 0.1) !important;
    color: $color-primary-blue !important;
    border-radius: 2rem !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    padding: 0.5rem 1.2rem !important;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba($color-primary-blue, 0.2) !important;
    }
  }

  &__button {
    display: inline-block;
    background-color: $color-primary-blue;
    color: white;
    padding: 0.8rem 1.8rem;
    border-radius: 3rem;
    font-size: 1.4rem;
    font-weight: 600;
    transition: all 0.3s ease;
    text-align: center;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba($color-primary-blue, 0.2);
      color: white;
    }

    &:active {
      transform: translateY(0);
    }
  }
}
